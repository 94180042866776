:root {
  --primary-color: #00d632;
  --secondary-color: #89939b;
  --text-color: #005620;
  --background-color: #f8f9fa;
}

body {
  background-color: var(--primary-color);
}

@media (max-width: 767px) {
  .transaction-id {
    background-color: var(--text-color);
    color: var(--primary-color);
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .transaction-id:hover {
    background-color: var(--primary-color);
    color: var(--text-color);
  }
}

.transaction-status-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-family: 'Segoe UI', sans-serif;
}

.transaction-status-header {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
}

.transaction-status-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transaction-beneficiary-account {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.transaction-amount {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.transaction-status {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.transaction-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.transaction-info p {
  margin-bottom: 0.5rem;
}

.transaction-info h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.transaction-info h2:hover {
  cursor: pointer;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.transaction-info span {
  display: none;
}

/*////////////////*/

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-family: 'Segoe UI', sans-serif;
  transform-origin: top;
  transform: scale(1);
  height: 100vh; /* take up the full height of the viewport */
  box-sizing: border-box; /* include padding in the height calculation */
}

.header-section-small {
  transform: scale(0.6);
  transition: transform 2s ease-in-out, height 2s ease-in-out,
    padding-top 2s ease-in-out;
  /* transition: padding-top 2s ease-in-out; */
  height: 33.33vh; /* reduce height to a third of the viewport */
  padding-top: 20rem;
}

.header-section h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.header-section p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
}

.button-section {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 100%;
}

.button-section a {
  display: inline-block;
  /* background-color: black; */
  color: white;
  border: none;
  border-radius: 0.5rem;
  /* padding: 0.5rem 1rem; */
  margin: 0 1rem;
  font-size: 1rem;
  /* font-weight: bold; */
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  max-width: 40%;
}

.button-section a img {
  width: 100%;
  max-width: 15rem;
}

.button-section a:hover {
  /* background-color: var(--text-color); */
  color: var(--primary-color);
  cursor: pointer;
}

.transaction-status-page {
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease-in-out, visibility 2s ease-in-out;
}

.transaction-status-page.visible {
  opacity: 1;
  visibility: visible;
}
